import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { convertValueToSlug, removeApolloCacheKeys } from "../../Utils/Utils";
import { labelsPrefix } from "../../Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import { executeVendureQuery, getVendureApiData } from "../../Utils/Integrations/useVendure";
import { Session } from "../../Utils/Session";
import _ from "lodash";

const UpdateLabels = ({ client }) => {
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const dispatch = useDispatch();
    const currentTokenShop = Session.getSessionProp("tokenShop");

    useEffect(() => {
        let execute = false;
        let mutation = null;
        let executeAnotherQuery = null;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "delete-label":
                    mutation = `
                        mutation { 
                            deleteFacetValues(force:true,ids:[${actionData.itemsAffected[0]}]){
                                result
                                message
                            }
                        }
                    `;
                    executeQuery.closeModal = true;
                    executeQuery.mutationName = "deleteFacetValues";
                    break;
                case "edit-label":
                    let translationMutations = "";
                    if (actionData?.values?.["translations"] && actionData.values["translations"].length > 0) {
                        actionData.values["translations"].forEach((translation) => {
                            if (translation.value && translation.value !== "") {
                                translationMutations += `{
                                    ${translation.id ? `id:${translation.id}` : ``}
                                    languageCode: ${translation.languageRef}
                                    name: "${(translation?.value || "").replace(/"/g, '\\"')}"
                                }`;
                            }
                        });
                    }
                    mutation = `
                        mutation {
                            updateFacetValues(
                                input: {
                                    id:${actionData.itemsAffected[0]},
                                    ${
                                        translationMutations && translationMutations !== ""
                                            ? `translations: [${translationMutations}] `
                                            : ``
                                    }
                                }
                            ) {
                                id
                            }
                        }
                    `;
                    executeQuery.closeModal = true;
                    executeQuery.mutationName = "updateFacetValues";
                    break;
                case "add-label":
                    translationMutations = "";
                    let defaultLangTranslation = null;
                    if (actionData?.values?.["translations"] && actionData.values["translations"].length > 0) {
                        actionData.values["translations"].forEach((translation) => {
                            if (translation.value && translation.value !== "") {
                                if (translation.isDefault) defaultLangTranslation = translation;
                                translationMutations += `{
                                    languageCode: ${translation.languageRef}
                                    name: "${(translation?.value || "").replace(/"/g, '\\"')}"
                                }`;
                            }
                        });
                    }
                    if (defaultLangTranslation) {
                        mutation = `
                            mutation{
                                createFacetValues(input:{
                                    facetId:${actionData.values["facetId"]}
                                    code:"${labelsPrefix}_${convertValueToSlug(defaultLangTranslation.value)}"
                                    ${
                                        translationMutations && translationMutations !== ""
                                            ? `translations: [${translationMutations}]`
                                            : ``
                                    }
                                }){
                                    id
                                }
                            }
                        `;
                        executeQuery.closeModal = true;
                        executeQuery.mutationName = "createFacetValues";
                    } else {
                        cancelAction();
                    }
                    break;
                default:
                    mutation = null;
                    execute = false;
                    break;
            }
        }

        if (execute) {
            dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation(mutation, executeAnotherQuery);
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const executeMutation = async (mutation, executeAnotherQuery) => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }
        let errorMutation = false;
        const response = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: mutation },
            { "vendure-token": currentTokenShop }
        );
        if (response && response.data && !response.data.errors) {
            if (executeQuery.mutationName) {
                let mutationObject = response.data[executeQuery.mutationName];
                if (mutationObject && mutationObject.result && mutationObject.result.toUpperCase().includes("NOT")) {
                    errorMutation = true;
                }
            }
        } else {
            errorMutation = true;
        }
        if (!errorMutation) {
            if (executeAnotherQuery) {
                let params = executeAnotherQuery.extraParams ? executeAnotherQuery.extraParams : {};
                if (executeAnotherQuery.useResponsePrevQueryData) {
                    let param = executeAnotherQuery.useResponsePrevQueryData;
                    params[param.name] = _.get(response.data, param.path);
                }
                dispatch(
                    setExecuteQuery({
                        action: executeAnotherQuery.action,
                        params: params,
                    })
                );
            } else {
                toast.success(t("operation-successful"));
                dispatch(setExecuteQuery(null));
                dispatch(cleanAction());
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
                if (executeQuery.cacheKeyToDelete) {
                    removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
                }
            }
        } else {
            dispatch(setExecuteQuery(null));
            dispatch(setLoading(false));
            toast.error(t("mutation-error"));
        }
    };

    const cancelAction = async () => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }
        dispatch(setRefreshContentData(true));
        dispatch(setRefreshData(true));
        dispatch(setExecuteQuery(null));
        dispatch(cleanAction());
        dispatch(setLoading(false));
        if (executeQuery.cacheKeyToDelete) {
            removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
        }
    };

    return null;
};

export default withApollo(UpdateLabels);
