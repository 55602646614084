import React, { useRef, useContext, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ShopContext } from "contexts/Sales/Shop";

import SettingsCard from "components/SettingsCard";
import UseSectionHeader from "components/useSectionHeader";
import Loading from "components/Loading";
import InfoIcon from "components/InfoIcon";
import CurrencyInput from "components/Inputs/CurrencyInput.jsx";
import Checkbox from "components/Inputs/Checkbox.jsx";

import EditRoomService from "./Delivery.RoomService.jsx";
import EditGeolocation from "./Delivery.Geolocation.jsx";
import EditPickup from "./Delivery.Pickup.jsx";

import { parseVendureTranslation } from "hooks/Utils/SalesUtils";

import {
    DELIVERY_METHOD_ROOM,
    DELIVERY_METHOD_PICKUP,
    DELIVERY_METHOD_GEOLOCATION,
    DELIVERY_METHODS,
    sortDeliveryMethods,
    getDeliveryMethodText,
} from "constants/sales";

const Delivery = () => {
    const { t } = useTranslation();

    const editPickupRef = useRef();

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const { id, loading, shippingConfig, pickupLocations, setDeliveryMethodAvailable } = useContext(ShopContext);

    const availableLocationsCount = pickupLocations?.filter((location) => location.available).length || 0;

    const projectLangs = useSelector((state) => state?.ui?.projectLangs);
    const defaultLang = projectLangs?.find((lang) => lang.isDefault)?.languageRef;

    const [editing, setEditing] = useState({});

    const breadcrumbs = [
        {
            name: t("shop-settings"),
            route: `/services/sales/shop/settings/${id}`,
        },
        {
            name: t("orders-and-delivery"),
            route: `/services/sales/shop/settings/${id}`,
        },
    ];

    useEffect(() => {
        if (!loading) {
            setIsFirstLoad(false);
        }
    }, [loading, shippingConfig]);

    const enabledDeliveryMethodsCount = DELIVERY_METHODS.filter((key) => shippingConfig?.[key]?.enabled).length;

    return (
        <>
            <UseSectionHeader title={t("Delivery method allowed")} customBreadCrumbs={breadcrumbs} />
            {loading && isFirstLoad ? (
                <Loading />
            ) : (
                <div className={loading ? "opacity-50" : ""}>
                    {shippingConfig
                        ? sortDeliveryMethods(DELIVERY_METHODS).map((key) => {
                              const isPickup = key === DELIVERY_METHOD_PICKUP;
                              const isRoomService = key === DELIVERY_METHOD_ROOM;
                              const isGeolocation = key === DELIVERY_METHOD_GEOLOCATION;
                              const isEditing = editing[key];
                              const isEnabled = shippingConfig[key]?.enabled;
                              const canDisable = enabledDeliveryMethodsCount > 1;

                              const title = t(getDeliveryMethodText(key));
                              const info = isGeolocation && !isEditing ? t("geolocation-info") : null;

                              const toggleButton = !(isPickup && isEditing)
                                  ? {
                                        checked: isEnabled,
                                        disabled:
                                            (isEnabled && !canDisable) ||
                                            loading ||
                                            (isPickup && availableLocationsCount === 0),
                                        label: t("available"),
                                        action: (value) => {
                                            setDeliveryMethodAvailable(key, value).then(() => {
                                                toast.success(t("operation-successful"));
                                            });
                                        },
                                    }
                                  : null;

                              const editButton = !isEditing
                                  ? {
                                        onClick: () => {
                                            setEditing({ ...editing, [key]: true });
                                        },
                                    }
                                  : null;

                              const editClose = (changed) => {
                                  setEditing({ ...editing, [key]: false });
                              };

                              return (
                                  <SettingsCard
                                      className="mb-5 py-4"
                                      key={key}
                                      id={key}
                                      title={
                                          <div className="flex pl-10 text-base space-x-5 items-center ">
                                              {isPickup ? (
                                                  <PickupHeader
                                                      title={title}
                                                      isEditing={isEditing}
                                                      lang={defaultLang}
                                                      amount={availableLocationsCount}
                                                  />
                                              ) : (
                                                  <div className="py-2 flex items-center">
                                                      <div>{title}</div>
                                                      {info ? <InfoIcon icon={"info"}>{info}</InfoIcon> : null}
                                                  </div>
                                              )}
                                          </div>
                                      }
                                      toggle={toggleButton}
                                      edit={editButton}
                                      right={
                                          isPickup && isEditing ? (
                                              <button
                                                  id="add-location-button"
                                                  onClick={() => {
                                                      if (editPickupRef.current) {
                                                          editPickupRef.current.addLocation();
                                                      }
                                                  }}
                                                  disabled={loading}
                                                  className="text-blue-300 font-bold"
                                              >{`+ ${t("Add location")}`}</button>
                                          ) : null
                                      }
                                  >
                                      {isEditing &&
                                          !loading && [
                                              isPickup && (
                                                  <EditPickup
                                                      ref={editPickupRef}
                                                      onClose={editClose}
                                                      defaultLang={defaultLang}
                                                  />
                                              ),
                                              isRoomService && <EditRoomService onClose={editClose} />,
                                              isGeolocation && (
                                                  <EditGeolocation defaultLang={defaultLang} onClose={editClose} />
                                              ),
                                          ]}
                                  </SettingsCard>
                              );
                          })
                        : null}
                </div>
            )}
        </>
    );
};

const PickupHeader = ({ title, isEditing, lang, amount }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={`min-w-52 ${isEditing ? null : "border-r-2"}`}>
                <div className="py-2">{title}</div>
            </div>
            <div className="font-normal">
                {isEditing
                    ? t(`language:${lang}`) + ` (${t("default-lang")})`
                    : t("Locations available x", {
                          count: amount || 0,
                      })}
            </div>
        </>
    );
};

const applyTax = (value, taxRate) => {
    if (isNaN(value)) {
        return value;
    }
    return taxRate ? Math.round((value * ((100 + taxRate) * 100)) / 100) / 100 : value;
};

const removeTax = (value, taxRate) => {
    return taxRate ? Math.round((value * 10000) / (100 + taxRate)) / 100 : value;
};

export const FooterButtons = ({ id, disabled, onCancel, onSave }) => {
    const { t } = useTranslation();

    if (!onCancel && !onSave) {
        throw new Error("No actions provided");
    }

    const cancelID = id ? `${id}-cancel` : null;
    const saveID = id ? `${id}-save` : null;

    return (
        <div className={`pt-6 space-x-5 text-center flex justify-end ${disabled ? "opacity-50" : ""}`}>
            {onCancel ? (
                <button
                    id={cancelID}
                    disabled={disabled}
                    onClick={onCancel}
                    className="btn-white p-4 rounded btn-blue-outline"
                >
                    {t("cancel")}
                </button>
            ) : null}
            {onSave ? (
                <button id={saveID} disabled={disabled} onClick={onSave} className="btn-blue p-4 rounded">
                    {t("save")}
                </button>
            ) : null}
        </div>
    );
};

export const EditPrice = ({ id: inputID, price: priceCents, taxRate: taxRateID, onChangePrice }) => {
    const { t } = useTranslation();

    const { taxRates, currency } = useContext(ShopContext);

    const price = priceCents / 100 || 0;
    const storedTaxRate = taxRateID && taxRates ? taxRates.find((rate) => Number(rate.id) === Number(taxRateID)) : null;
    const priceWithTax = applyTax(price, storedTaxRate?.value ?? 0);

    const [priceChecked, setPriceChecked] = useState(price > 0);
    const [newPriceWithoutTax, setNewPriceWithoutTax] = useState(price ?? null);
    const [newPriceWithTax, setNewPriceWithTax] = useState(priceWithTax ?? null);
    const [newTaxRateID, setNewTaxRateID] = useState(storedTaxRate?.id ?? null);

    const newTaxRate =
        newTaxRateID && taxRates ? taxRates.find((rate) => Number(rate.id) === Number(newTaxRateID)) : null;

    useEffect(() => {
        if (!priceChecked) {
            setNewPriceWithoutTax(0);
            setNewPriceWithTax(0);
        }
    }, [priceChecked]);

    useEffect(() => {
        if (onChangePrice) {
            onChangePrice({
                price: newPriceWithoutTax === null ? null : newPriceWithoutTax * 100,
                taxRate: newTaxRate,
            });
        }
    }, [newPriceWithoutTax, newTaxRateID]);

    useEffect(() => {
        setNewPriceWithTax(applyTax(newPriceWithoutTax, newTaxRate?.value ?? 0));
    }, [newTaxRateID]);

    const togglePrice = ({ checked }) => {
        setPriceChecked(checked);
    };

    const inputPriceID = inputID;
    const inputTaxID = `${inputID}-tax`;
    const inputTotalID = `${inputID}-total`;
    const inputCheckPriceID = `${inputID}-set`;

    if (taxRateID && taxRates && !storedTaxRate) {
        console.warn("Tax rate not found for id", taxRateID);
    }

    const selectStyle = `rounded p-2 cursor-pointer ${
        !priceChecked ? "text-gray-700 bg-gray-400" : "text-gray-900 bg-gray-200"
    } ${!newTaxRateID ? "rounded border border-orange-100" : ""}`;

    return (
        <>
            <div className="w-full flex items-center mb-2">
                <Checkbox id={inputCheckPriceID} checked={priceChecked} onChange={togglePrice} label={t("set-price")} />
            </div>
            <div className="w-full flex items-center mb-2 pl-8">
                <div className="w-32">{t("without-tax")}</div>
                <CurrencyInput
                    id={inputPriceID}
                    value={newPriceWithoutTax}
                    disabled={!priceChecked}
                    onChange={(value) => {
                        if (priceChecked) {
                            setNewPriceWithoutTax(value);
                            setNewPriceWithTax(applyTax(value, newTaxRate?.value ?? 0));
                        }
                    }}
                    currencyCode={currency?.code}
                    className={"w-32"}
                />
            </div>
            <div className="w-full flex items-center mb-2 pl-8">
                <div className="w-32">{t("tax")}</div>
                <select
                    id={inputTaxID}
                    value={newTaxRateID}
                    disabled={!priceChecked}
                    className={selectStyle}
                    onChange={(e) => {
                        setNewTaxRateID(e.target.value);
                    }}
                >
                    {taxRates.map((rate) => (
                        <option key={rate.id} value={rate.id}>
                            {`${rate.name} (${rate.value}%)`}
                        </option>
                    ))}
                </select>
                {!newTaxRateID ? (
                    <div className="rounded text-sm bg-orange-200 text-orange-600 py-1 px-2 ml-2">
                        Warning: No tax rate stored, please select one and save
                    </div>
                ) : null}
            </div>
            <div className="w-full flex items-center mb-2 pl-8">
                <div className="w-32">{t("total")}</div>
                <CurrencyInput
                    id={inputTotalID}
                    value={newPriceWithTax}
                    disabled={!priceChecked}
                    onChange={(value) => {
                        if (priceChecked) {
                            const base = removeTax(value, newTaxRate?.value ?? 0);
                            setNewPriceWithoutTax(base);
                            setNewPriceWithTax(applyTax(base, newTaxRate?.value ?? 0));
                        }
                    }}
                    currencyCode={currency?.code}
                    className={"w-32"}
                />
            </div>
        </>
    );
};

export const Translations = forwardRef(
    (
        {
            id,
            languages,
            translations: translationsValue,
            defaultLang,
            onChangeTranslations,
            className,
            maxLength,
            maxHeight,
            multiline,
            template,
            includeDefault = true,
        },
        ref
    ) => {
        const { t } = useTranslation();

        const [translations, setTranslations] = useState(translationsValue);

        useEffect(() => {
            if (typeof onChangeTranslations === "function") {
                onChangeTranslations(translations);
            }
        }, [translations]);

        // get best translation by language code
        const getName = (lang) => parseVendureTranslation(translations, lang, { defaultLang });
        // get stored translation by language code
        const getNameTranslation = (lang) => parseVendureTranslation(translations, lang, { exact: true, defaultLang });

        const usingTemplate = template && JSON.stringify(translations) === JSON.stringify(template);

        const useTemplate = () => {
            if (template) {
                setTranslations(template);
            }
        };

        const onChangeTranslation = (value, lang) => {
            setTranslations(changeTranslation(translations, lang, value));
        };

        useImperativeHandle(ref, () => ({
            changeTranslation: (value, lang) => {
                onChangeTranslation(value, lang);
            },
        }));

        return (
            <div className={className}>
                {includeDefault ? (
                    <>
                        <div className="py-1">
                            {t("default-language")}
                            {useTemplate ? (
                                <button
                                    id={`${id}-template`}
                                    onClick={useTemplate}
                                    className={`float-right font-bold first-capital ${
                                        usingTemplate ? "text-gray-700" : "text-zafiro-600"
                                    }`}
                                >
                                    {t("use-template")}
                                </button>
                            ) : null}
                        </div>

                        <div className="flex items-start px-5 pt-2 space-x-5">
                            <div className="min-w-24 text-base font-bold">{t(`language:${defaultLang}`)}</div>
                            <TextInput
                                id={`${id}-default`}
                                className="w-full"
                                value={getNameTranslation(defaultLang)}
                                placeholder={getName(defaultLang)}
                                maxLength={maxLength}
                                multiline={multiline}
                                displayCount={true}
                                onChange={(name) => {
                                    onChangeTranslation(name, defaultLang);
                                }}
                            />
                        </div>
                    </>
                ) : null}
                <div className="py-1">{t("translations")}</div>
                <div className="border rounded p-5 space-y-3 overflow-auto" style={{ maxHeight }}>
                    {languages.map((lang) => (
                        <div key={lang} className="flex items-start space-x-5">
                            <div className="min-w-24 text-base font-bold">{t(`language:${lang}`)}</div>
                            <TextInput
                                id={`${id}-${lang}`}
                                className="w-full"
                                value={getNameTranslation(lang)}
                                placeholder={getName(lang)}
                                maxLength={maxLength}
                                multiline={multiline}
                                onChange={(name) => {
                                    onChangeTranslation(name, lang);
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
);

export const TextInput = ({
    id,
    value: initialName,
    placeholder,
    maxLength,
    multiline,
    displayCount,
    onChange,
    className,
}) => {
    const { t } = useTranslation();
    const [name, setName] = useState(initialName);

    useEffect(() => {
        setName(initialName);
    }, [initialName]);

    const inputProps = {
        id,
        value: name ?? "",
        placeholder,
        maxLength,
        onChange: (e) => setName(e.target.value),
        className: "bg-gray-200 text-gray-900 py-1 px-3 w-full rounded",
        onBlur: () => {
            if (onChange) {
                onChange(name);
            }
        },
    };

    return (
        <div className={className}>
            {multiline ? <textarea {...inputProps} rows={multiline} /> : <input {...inputProps} />}
            {displayCount && maxLength !== undefined ? (
                <span className="float-right text-gray-800 text-sm">
                    {t("x/y characters", { current: name?.length || 0, count: maxLength })}
                </span>
            ) : null}
        </div>
    );
};

export const changeTranslation = (translations, lang, value) => {
    let newTranslations = [...(translations || []), { languageCode: lang, name: value }];
    // remove duplicated translations by languageCode, leaving the last one
    newTranslations = [
        ...new Map(
            newTranslations.map((translation) =>
                translation?.languageCode ? [translation.languageCode, translation] : null
            )
        ).values(),
    ];
    // remove empty translations
    newTranslations = newTranslations.filter((translation) => translation?.name?.length > 0);
    return newTranslations;
};

export default Delivery;
